body {
  background-color: #001e2b;
  color: white;
}

.bg-dark {
  background-color: #1c2d38;
  color: white;
  border-color: #1c2d38;
}
